<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import news from '../News'
import tinymce from '@/shared/tinymce'
import ImageRotate from '@/shared/ImageRotate'
import SocialPostForm from '@/views/marketing/media-manager/details/Form.vue'
import { permissionCheck } from '@/shared/permission.js';

export default {
  name: "Details",
  components: {'tinymceEditor': Editor, ImageRotate, SocialPostForm},
  mixins: [news, tinymce],
  data: function () {
    return {
      permissionCheck: permissionCheck,
      new_picture: null,
      news: {
        id: 0,
        date: (new Date()).toISOString().substr(0, 10),
        title: '',
        text: '',
        picture: null,
        active: true,
        facebook_posted: false,
        display_website: 1,
      },
    }
  },
  created: function(){
    let self = this;
    if(parseInt(self.$route.params.id) > 0){
      Http.request('GET', '/news/' + self.$route.params.id).then((news) => {
        self.news = news.data;
        self.news.display_website = 1;
      });
    }
  },
  methods: {
    onFileChange(e) {
      this.new_picture = e.target.files[0];
      this.news.picture = URL.createObjectURL(this.new_picture);
    },
    save(){
      let self = this;

      if (typeof self.$refs.socialPosts == "undefined" || self.$refs.socialPosts.validate()) {
        let formData = new FormData();
        self.$refs.imageRotate.getImage(true).then(function (imageRotateResponse){
          if(imageRotateResponse){
            self.new_picture = imageRotateResponse;
          }
          if(self.new_picture !== null) {
            formData.append('new_picture', self.new_picture);
          }
          formData.append('date', self.news.date);
          formData.append('title', self.news.title);
          formData.append('text', self.news.text);
          formData.append('display_website', self.news.display_website);

          Http.request('POST', '/news/' + self.news.id, formData).then((news) => {
            if(self.$refs.socialPosts){
              self.$refs.socialPosts.store({type: 'news', data: news.data}).then(function (){
                self.$router.push('/website/news');
              });
            }
            else {
              self.$router.push('/website/news');
            }
          });
        });
      }
    },
  }
}
</script>

<style scoped></style>
